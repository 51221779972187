import Vue from 'vue';
import Vuex from 'vuex';
import { commonStore } from '@/utils/store.js';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    ...commonStore.state,
  },
  getters: {
    ...commonStore.getters,
  },
  mutations: {
    ...commonStore.mutations,
  },
  actions: {
    ...commonStore.actions,
  },
});

export { store };
