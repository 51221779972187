import Vue from 'vue';
import { getAxios } from '@/utils/axios';

const APPKEY = 'ee5030bda3124ff58a7ce72eb881fed0';
const APPID = '100005';
const axios = getAxios(APPID, APPKEY);

Vue.prototype.$axios = axios;

export { axios };
