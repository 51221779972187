import { Router, scrollBehavior } from '@/utils/router.js';
import { setHtmlMeta } from '@/utils/utils';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "core_www" */ './home/home.vue'),
      meta: { desc: '首页' },
    },
    {
      path: '/privacy.html',
      name: 'PrivacyPolicy',
      component: () => import(/* webpackChunkName: "core_www" */ './privacy.vue'),
      meta: { desc: '约克论坛-隐私权政策' },
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import(/* webpackChunkName: "core_www" */ './search/search.vue'),
      meta: { desc: '搜索' },
    },
    {
      path: '/websafe',
      name: 'WebSafe',
      component: () => import(/* webpackChunkName: "core_www" */ '@/common/websafe.vue'),
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "core_www" */ '@/common/404/index.vue'),
      meta: { desc: '404' },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  if (to.name == 'Home') {
    setHtmlMeta({
      title: '约克论坛 - 加拿大第一中文网',
      keywords: 'Yorkbbs.ca,加拿大华人中文网,多伦多华人网,多伦多新闻,华人论坛',
      description:
        '约克论坛（yorkbbs.ca）是加拿大排名第一的中文网站，是多伦多最具影响力的中文网络媒体。为多伦多华人和留学生提供加拿大新闻、房产、汽车、黄页、美食、旅游、留学移民、财经、招聘、二手、活动优惠等生活信息。',
    });
  }else if(to.name == 'Search'){
    setHtmlMeta({
      title:'约克论坛 - 加拿大第一中文网',
      keywords:'Yorkbbs.ca,加拿大华人中文网,多伦多华人网,多伦多新闻,华人论坛',
      description:'约克论坛（yorkbbs.ca）是加拿大排名第一的中文网站，是多伦多最具影响力的中文网络媒体。为多伦多华人和留学生提供加拿大新闻、房产、汽车、黄页、美食、旅游、留学移民、财经、招聘、二手、活动优惠等生活信息。'
    });
  } else if (to.name == 'WebSafe') {
    setHtmlMeta({ title: '外链 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'NotFound') {
    setHtmlMeta({ title: '404 - 约克论坛', keywords: '', description: '' });
  } else {
    setHtmlMeta({ title: '', keywords: '', description: '' });
  }

  if (to.name == 'NotFound' && !to.query.from) return next({ name: 'NotFound', query: { from: location.href } });

  next();
});

export { router };
